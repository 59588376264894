.page-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-not-found h1 {
  color: #555;
  font-weight: lighter;
}

@media screen and (max-width: 750px) {
  .page-not-found h1 {
    font-size: 2rem;
  }
}

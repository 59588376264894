.home {
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url(../../img/header-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.home > img {
  position: absolute;
  bottom: 10%;
  left: 15%;
}

@media screen and (max-width: 1000px) {
  .home > img {
    display: none;
  }
}

.wrapper {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  /* text-align: left; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
}

.left-side {
  margin-top: 3rem;
}

.right-side {
  width: 30%;
}

.right-side img {
  width: 100%;
}

.left-side h1 {
  font-size: 5rem;
  font-family: var(--second-font);
  font-weight: 300;
}

.left-side h1 span {
  color: var(--green-nav);
  font-family: var(--second-font);
}

.left-side p {
  font-size: 1.2rem;
  margin: 2rem 0 4rem;
  line-height: 32px;
}

.more-link {
  padding: 10px 20px;
  border-radius: 16px;
  color: white;
  text-decoration: none;
  background: linear-gradient(to right, #128656ff 0%, #62d6a6ff 100%);
  font-size: 1rem;
  text-transform: uppercase;
  display: block;
  width: fit-content;
  margin-bottom: 16px;
}

.more-link:hover {
  background: #349b70;
}

.left-side img {
  height: 200px;
}

@media screen and (max-width: 1000px) {
  .wrapper {
    position: relative;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 8rem;
    justify-content: center;
    align-items: center;
  }

  .right-side {
    display: grid;
  }

  .right-side img {
    width: 85%;
    margin-top: 3rem;
    justify-self: center;
  }

  .right-side {
    width: 90%;
  }

  .left-side img {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .home {
    min-height: 100vh;
    /* height: max-content;         */
  }

  h1 {
    font-size: 3.5rem;
    text-align: center;
  }

  .left-side p {
    font-size: 1rem;
    text-align: center;
    margin: 0 2rem 2rem 0;
    margin-top: 2rem;
  }

  .left-side {
    display: grid;
  }

  .more-link {
    margin-top: 3rem;
    font-size: 1rem;
    text-align: center;
    padding: 12px 20px;
    width: fit-content;
    justify-self: center;
  }
}

@media screen and (max-width: 500px) {
  .left-side h1 {
    font-size: 3rem;
  }

  .wrapper {
    margin-top: 6rem;
    /* top: 50%; */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --green-nav: #128656;
  --gray-color: #6b7688;
  --secondary-green: #54bb90;
  --blue-black: #03331f;
  --blue-white: #f6fffb;
  --font-base: "Raleway", sans-serif;
  --second-font: "Inria Serif", serif;
}

* {
  font-family: var(--font-base);
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* scroll-behavior: smooth; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
}

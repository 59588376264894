.gallery {
  width: 100%;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.gallery h1 {
  text-transform: uppercase;
  font-size: 2rem;
  margin-top: 5rem;
  font-weight: 500;
}

.gallery h1 span {
  color: white;
  background: linear-gradient(to right, #03331f 53%, #08995d 100%);
  padding-left: 2rem;
}

.gallery-imgs {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  /* -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%; */
  padding: 0 12px;
  margin-top: 3rem;
}

.gallery-imgs .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery-imgs .pics:hover {
  filter: opacity(0.8);
}

.gallery-imgs .pics img {
  width: 100%;
}

@media (max-width: 991px) {
  .gallery-imgs {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery-imgs {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    /* -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%; */
  }
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: visibility 0.4s ease, opacity 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 5;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}

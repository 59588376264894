.our-homes {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dddddd30;
  padding: 7rem 0;
}

.our-homes h3 {
  font-size: 1.1rem;
  margin: 1.2rem 0;
}

@media screen and (max-width: 750px) {
  .our-homes h1 {
    font-size: 2.2rem;
  }
}

.features {
  list-style: none;
}

.tabs {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}

.tab {
  width: 50%;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-top: 2rem;
  animation: mytabs;
}

@media screen and (max-width: 750px) {
  .tabs {
    width: 90%;
  }
}

.tab p {
  text-align: center;
  font-size: 1.1rem;
  font-weight: bolder;
  margin-bottom: 15px;
}

.tab div {
  height: 8px;
  width: 100%;
  border: 1px solid black;
}

/* .tab:nth-of-type(1) > div {
    background-color: black;
} */

.tab.active div {
  background-color: var(--blue-black);
}

.cities {
  display: flex;
  justify-content: center;
  align-items: stretch;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 5px 2px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  width: 100%;
}

.tab-content {
  background-color: var(--blue-white);
  padding: 3rem;
  width: 100%;
  transition: all 0.1s ease;
}

@media screen and (max-width: 750px) {
  .cities {
    flex-direction: column;
    width: 100%;
  }

  .tab-content {
    padding: 1rem;
  }
}

.tab-content.active {
  background-color: var(--blue-black);
  color: white;
}

.tab-content h1 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.tab-content h2 {
  font-size: 1rem;
  font-weight: bolder;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.tab-content p {
  font-size: 1rem;
}

.tab-content .both {
  text-align: right;
}

.tab-content ul li {
  margin-bottom: 1rem;
}

.first_home,
.second_home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
}

.right-part {
  width: 45%;
}

.right-part img {
  width: 100%;
}

.aa-image {
  width: 100%;
  height: 100%;
  position: relative;
}

.aa-image img {
  width: 100%;
  height: 100%;
}

.aa-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.addis-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #128656cc;
  padding: 2rem;
}

.addis-header h1 {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
}

.addis-header p {
  color: white;
  font-size: 1.2rem;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.addis-header p a {
  color: white;
  text-decoration: none;
  transition: all 0.2s ease;
}

.addis-header p svg {
  margin-right: 10px;
}

.addis-header p a:hover {
  color: #62d6a6;
}

.aa-image > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
}

.tab-content:last-child {
  padding: 0;
}

@media (max-width: 1264px) {
  .first_home,
  .second_home {
    flex-direction: column;
    align-items: start;
  }
  .second_home img {
    display: none;
  }
}

@media (max-width: 750px) {
  .first_home,
  .second_home {
    align-items: center;
  }

  .first_home .right-part {
    width: 60%;
  }
}

@media (max-width: 450px) {
  .first_home .right-part {
    width: 90%;
  }

  .aa-image h1 {
    padding: 1rem;
    font-size: 1rem;
  }
}

.left-part p a {
  color: white;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.left-part p a:hover {
  transform: translate(10, 10);
}

@media (max-width: 900px) {
  .addis-header p {
    font-size: 1rem;
  }
}

@media (max-width: 750px) {
  .aa-image {
    height: 70vh;
  }

  .addis-header {
    padding: 1rem;
  }
}

.testimonial {
  /* height: 100vh; */
  width: 100%;
  /* margin-bottom: 5rem; */
}

.testimonial-imgs {
  width: 100%;
  height: 22rem;
}

.testimonial-imgs img {
  width: 25%;
  height: 100%;
}

.testimonial-imgs img:nth-child(3) {
  width: 30%;
}

.testimonial-imgs img:nth-child(4) {
  width: 20%;
}

@media screen and (max-width: 990px) {
  .testimonial-imgs {
    height: 18rem;
  }
}

@media screen and (max-width: 750px) {
  .testimonial-imgs {
    height: 10rem;
  }
}

.testimonial .bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.testimonial .bottom h1 {
  font-family: var(--second-font);
  font-size: 3rem;
  font-weight: 500;
}

.testimonial .bottom .underline {
  height: 5px;
  width: 8rem;
  background-color: var(--green-nav);
  margin-top: 10px;
}

.buttons {
  width: 100px;
  display: flex;
  justify-content: space-between;
}

.buttons svg {
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 50%;
  background-color: var(--secondary-green);
  color: white;
  cursor: pointer;
}

.buttons svg:hover {
  background-color: var(--green-nav);
}

.container {
  width: 100%;
}

@media screen and (max-width: 750px) {
}

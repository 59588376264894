.cards-container {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -6rem;
  z-index: 2;
}

@media screen and (max-width: 990px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
}

.card {
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 25%;
  background-color: var(--blue-white);
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
}

.card:nth-child(2) {
  background-color: var(--blue-black);
  color: white;
}

@media screen and (max-width: 990px) {
  .card {
    width: 90%;
    margin-top: 2rem;
  }
}

.title-img img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.title-img {
  border-radius: 10px;
  background-color: var(--blue-black);
  padding: 10px 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-bg {
  background-color: var(--blue-white);
}

.card h1 {
  font-size: 2rem;
  font-weight: 600;
  font-family: var(--font-base);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.card p {
  font-size: 1.2rem;
  line-height: 30px;
}

.second-about {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 5rem;
}

.second-about .about-left {
  width: 30%;
  position: relative;
}

@media screen and (max-width: 750px) {
  .second-about {
    flex-direction: column;
  }

  .second-about .about-left {
    width: 90%;
  }
}

.about-left .old-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.about-left .bg {
  position: absolute;
  top: -10%;
  left: -15%;
  z-index: -1;
}

.about-right {
  width: 40%;
}

@media screen and (max-width: 750px) {
  .about-right {
    width: 90%;
  }

  .about-left .old-image {
    height: 350px;
  }
}

.about-right h3 {
  font-size: 3rem;
  margin: 2rem 0;
}

@media screen and (max-width: 750px) {
  .about-right h3 {
    font-size: 2rem;
  }
}

.about-right p {
  text-wrap: wrap;
  font-size: 1.1rem;
  line-height: 30px;
  margin-bottom: 1.3rem;
}

.about-right ul {
  list-style: none;
  margin-bottom: 2.5rem;
}

.about-right ul li {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.about-right ul li svg {
  color: #4a5764;
  font-size: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  background: #294da414 0% 0% no-repeat padding-box;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
}

footer {
  width: 100%;
}

.footer-bg {
  margin-bottom: -4rem;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .footer-bg {
    margin-bottom: -1rem;
  }
}

.copyright {
  background-color: #d4e4fa;
  text-align: center;
  padding: 1.5rem;
  font-weight: 600;
}

.copyright a {
  color: var(--green-nav);
  text-decoration: none;
}

.footer-main {
  background-color: #ecf2fa;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0;
  padding: 5rem;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .footer-main {
    justify-content: center;
    flex-direction: column;
    align-items: start;
  }

  .home-links {
    margin: 2rem 0;
  }
}

.social-links img {
  width: 160px;
  height: 70px;
}

footer ul {
  list-style: none;
  margin-top: 1.5rem;
}

footer ul li {
  margin: 1rem 0;
}

footer ul li a {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.2s ease;
}

footer ul li a:hover {
  color: var(--green-nav);
}

footer ul li a svg {
  margin-right: 10px;
}

.social {
  margin-top: 1rem;
}

.social svg {
  width: 40px;
  height: 40px;
  color: var(--green-nav);
  padding: 10px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: #d4e4fa;
}

.social-links p {
  font-size: 1rem;
  margin: 1.2rem 0;
  line-height: 25px;
}

.gallery-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.gallery-section .line {
  width: 200px;
  height: 3px;
  background-color: var(--secondary-green);
  margin-bottom: 3rem;
}

.gallery-section .swiper-container {
  width: 85%;
  height: 100%;
}

.gallery-section .swiper-slide {
  width: 100%;
  /* height: 80%; */
}

.gallery-section .swiper-slide img {
  width: 100%;
  height: 400px;
  /* height: 100%; */
  transform: rotate(0deg);
  object-fit: cover;
}

/* .swiper-slide:nth-child(6) img,
.swiper-slide:nth-child(7) img {
  transform: rotate(-180deg);
}

.swiper-slide:nth-child(8) img {
  transform: rotate(90deg);
} */

@media (max-width: 1300px) {
  .gallery-section .swiper-container {
    width: 90%;
  }
}

@media (max-width: 1000px) {
  .gallery-section .swiper-container {
    width: 100%;
  }
  .gallery-section .swiper-slide img {
    width: 500px;
    /* height: 60%; */
  }
}

@media (max-width: 900px) {
  .gallery-section .swiper-container {
    width: 100vw;
    /* height: 30vh; */
  }
  .gallery-section .swiper-slide {
    width: 100vw;
    /* height: 90vh; */
  }
  .gallery-section .swiper-slide img {
    width: 100%;
    height: 200px;
    /* transform: rotate(-90deg); */
  }

  /* .swiper-slide:nth-child(6) img,
  .swiper-slide:nth-child(7) img {
    transform: rotate(-180deg);
  }

  .swiper-slide:nth-child(8) img {
    transform: rotate(90deg);
  } */
}

.more-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  background: linear-gradient(to right, #03331f 53%, #08995d 100%);
  color: white;
  text-decoration: none;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.more-gallery svg {
  margin-left: 10px;
}

.swiper-pagination {
  color: white;
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: visibility 0.4s ease, opacity 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 5;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

.model svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
}

.gallery-slider-arrow {
  color: var(--secondary-green);
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 30px;
  box-sizing: border-box;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
